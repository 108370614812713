<template>
  <v-card
    class="charcoal d-flex flex-column noscroll-x topright"
    v-touch:swipe.left="cancel"
    tile
  >
    <BaseCloseDialogHeader
      class="silver--text"
      :heading="getHeading()"
      @cancel="cancel"
    />

    <template>
      <v-card-text class="pa-0 mt-n3">
        <v-sheet rounded class="charcoal charcoalTile px-2 mb-2 mx-2 ">
          <v-card-actions class="py-2 px-0" @click="toggleWorkout()">
            <span class="paper--text text-body-1 pt-2 my-2">
              <span :class="showWorkoutDetails ? 'gold--text' : 'paper--text'">
                WORKOUTS
              </span>
            </span>
            <v-spacer />
            <v-icon :color="showWorkoutDetails ? 'gold' : 'paper'">
              {{
                showWorkoutDetails
                  ? 'mdi-chevron-up-circle-outline'
                  : 'mdi-chevron-down-circle-outline'
              }}
            </v-icon>
          </v-card-actions>
          <template v-if="showWorkoutDetails">
            <v-row
              no-gutters
              v-for="item in workoutItems"
              :key="item.title"
              dense
              align="center"
              class="py-2"
            >
              <v-col cols="1" />
              <v-col cols="10">
                <v-card
                  class="transparent"
                  link
                  flat
                  :to="item.url"
                  @click="cancel"
                >
                  <v-card-actions class="paper--text text-subtitle-2 pa-0">
                    {{ item.title }}
                    <v-spacer />
                    <v-divider class="opacity-30" color="gold" />
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-sheet>
        <v-sheet rounded class="charcoal charcoalTile px-2 mb-2 mx-2 ">
          <v-card-actions class="py-2 px-0" @click="toggleNutrition()">
            <span class="paper--text text-body-1 pt-2 my-2">
              <span
                :class="showNutritionDetails ? 'gold--text' : 'paper--text'"
              >
                FOOD & NUTRITION
              </span>
            </span>
            <v-spacer />
            <v-icon :color="showNutritionDetails ? 'gold' : 'paper'">
              {{
                showNutritionDetails
                  ? 'mdi-chevron-up-circle-outline'
                  : 'mdi-chevron-down-circle-outline'
              }}
            </v-icon>
          </v-card-actions>
          <template v-if="showNutritionDetails">
            <v-row
              no-gutters
              v-for="item in nutritionItems"
              :key="item.title"
              dense
              align="center"
              class="py-2"
            >
              <v-col cols="1" />
              <v-col cols="10">
                <v-card
                  class="transparent"
                  link
                  flat
                  :to="item.url"
                  @click="cancel"
                >
                  <v-card-actions class="paper--text text-subtitle-2 pa-0">
                    {{ item.title }}
                    <v-spacer />
                    <v-divider class="opacity-30" color="gold" />
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-sheet>

        <v-sheet rounded class="charcoal charcoalTile px-2 mb-2 mx-2 ">
          <v-card-actions class="py-2 px-0" @click="toggleStats()">
            <span class="paper--text text-body-1 pt-2 my-2">
              <span :class="showStatsDetails ? 'gold--text' : 'paper--text'">
                STRENGTH & STATS
              </span>
            </span>
            <v-spacer />
            <v-icon :color="showStatsDetails ? 'gold' : 'paper'">
              {{
                showStatsDetails
                  ? 'mdi-chevron-up-circle-outline'
                  : 'mdi-chevron-down-circle-outline'
              }}
            </v-icon>
          </v-card-actions>
          <template v-if="showStatsDetails">
            <v-row
              no-gutters
              v-for="item in statsItems"
              :key="item.title"
              dense
              align="center"
              class="py-2"
            >
              <v-col cols="1" />
              <v-col cols="10">
                <v-card
                  class="transparent"
                  link
                  flat
                  :to="item.url"
                  @click="cancel"
                >
                  <v-card-actions class="paper--text text-subtitle-2 pa-0">
                    {{ item.title }}
                    <v-spacer />
                    <v-divider class="opacity-30" color="gold" />
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-sheet>
        <v-sheet
          rounded
          class="charcoal charcoalTile px-2 mb-2 mx-2 "
          v-if="trackingItems.length > 0"
        >
          <v-card-actions class="py-2 px-0" @click="toggleTracking()">
            <span class="paper--text text-body-1 pt-2 my-2">
              <span :class="showTrackingDetails ? 'gold--text' : 'paper--text'">
                TRACKING
              </span>
            </span>
            <v-spacer />
            <v-icon :color="showTrackingDetails ? 'gold' : 'paper'">
              {{
                showTrackingDetails
                  ? 'mdi-chevron-up-circle-outline'
                  : 'mdi-chevron-down-circle-outline'
              }}
            </v-icon>
          </v-card-actions>
          <template v-if="showTrackingDetails">
            <v-row
              no-gutters
              v-for="item in trackingItems"
              :key="item.title"
              dense
              align="center"
              class="py-2"
            >
              <v-col cols="1" />
              <v-col cols="10">
                <v-card
                  class="transparent"
                  link
                  flat
                  :to="item.url"
                  @click="cancel"
                >
                  <v-card-actions class="paper--text text-subtitle-2 pa-0">
                    {{ item.title }}
                    <v-spacer />
                    <v-divider class="opacity-30" color="gold" />
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-sheet>
        <v-sheet rounded class="charcoal charcoalTile px-2 mb-2 mx-2 ">
          <v-card-actions class="py-2 px-0" @click="toggleAI()">
            <span class="text-body-1 pt-2 my-2">
              <span :class="showAIDetails ? 'gold--text' : 'cyan--text'">
                BRUH AI
              </span>
            </span>
            <v-spacer />
            <v-icon :color="showAIDetails ? 'gold' : 'paper'">
              {{
                showAIDetails
                  ? 'mdi-chevron-up-circle-outline'
                  : 'mdi-chevron-down-circle-outline'
              }}
            </v-icon>
          </v-card-actions>
          <template v-if="showAIDetails">
            <v-row
              no-gutters
              v-for="item in aiItems"
              :key="item.title"
              dense
              align="center"
              class="py-2"
            >
              <v-col cols="1" />
              <v-col cols="10">
                <v-card
                  class="transparent"
                  link
                  flat
                  :to="item.url"
                  @click="cancel"
                >
                  <v-card-actions class="paper--text text-subtitle-2 pa-0">
                    {{ item.title }}
                    <v-spacer />
                    <v-divider class="opacity-30" color="gold" />
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-sheet>
        <v-sheet
          rounded
          class="charcoal charcoalTile px-2 mb-2 mx-2 "
        >
          <v-card-actions class="py-2 px-0" @click="toggleTools()">
            <span class="paper--text text-body-1 pt-2 my-2">
              <span :class="showToolsDetails ? 'gold--text' : 'paper--text'">
                TOOLS
              </span>
            </span>
            <v-spacer />
            <v-icon :color="showToolsDetails ? 'gold' : 'paper'">
              {{
                showToolsDetails
                  ? 'mdi-chevron-up-circle-outline'
                  : 'mdi-chevron-down-circle-outline'
              }}
            </v-icon>
          </v-card-actions>
          <template v-if="showToolsDetails">
            <v-row
              no-gutters
              v-for="item in toolsItems"
              :key="item.title"
              dense
              align="center"
              class="py-2"
            >
              <v-col cols="1" />
              <v-col cols="10">
                <v-card
                  class="transparent"
                  link
                  flat
                  :to="item.url"
                  @click="cancel"
                >
                  <v-card-actions class="paper--text text-subtitle-2 pa-0">
                    {{ item.title }}
                    <v-spacer />
                    <v-divider class="opacity-30" color="gold" />
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-sheet>
        <v-sheet rounded class="charcoal charcoalTile px-2 mb-2 mx-2 ">
          <v-card-actions class="py-2 px-0" @click="toggleLearning()">
            <span class="paper--text text-body-1 pt-2 my-2">
              <span :class="showLearningDetails ? 'gold--text' : 'paper--text'">
                LEARNING
              </span>
            </span>
            <v-spacer />
            <v-icon :color="showLearningDetails ? 'gold' : 'paper'">
              {{
                showLearningDetails
                  ? 'mdi-chevron-up-circle-outline'
                  : 'mdi-chevron-down-circle-outline'
              }}
            </v-icon>
          </v-card-actions>
          <template v-if="showLearningDetails">
            <v-row
              no-gutters
              v-for="item in learningItems"
              :key="item.title"
              dense
              align="center"
              class="py-2"
            >
              <v-col cols="1" />
              <v-col cols="10">
                <v-card
                  class="transparent"
                  link
                  flat
                  :to="item.url"
                  @click="cancel"
                >
                  <v-card-actions class="paper--text text-subtitle-2 pa-0">
                    {{ item.title }}
                    <v-spacer />
                    <v-divider class="opacity-30" color="gold" />
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-sheet>
        <v-sheet rounded class="charcoal charcoalTile px-2 mb-2 mx-2 ">
          <v-card-actions class="py-2 px-0" @click="toggleMisc()">
            <span class="paper--text text-body-1 pt-2 my-2">
              <span :class="showMiscDetails ? 'gold--text' : 'paper--text'">
                MISCELANEOUS
              </span>
            </span>
            <v-spacer />
            <v-icon :color="showMiscDetails ? 'gold' : 'paper'">
              {{
                showMiscDetails
                  ? 'mdi-chevron-up-circle-outline'
                  : 'mdi-chevron-down-circle-outline'
              }}
            </v-icon>
          </v-card-actions>
          <template v-if="showMiscDetails">
            <v-row
              no-gutters
              v-for="item in miscItems"
              :key="item.title"
              dense
              align="center"
              class="py-2"
            >
              <v-col cols="1" />
              <v-col cols="10">
                <v-card
                  class="transparent"
                  link
                  flat
                  :to="item.url"
                  @click="cancel"
                >
                  <v-card-actions class="paper--text text-subtitle-2 pa-0">
                    {{ item.title }}
                    <v-spacer />
                    <v-divider class="opacity-30" color="gold" />
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-sheet>
      </v-card-text>
    </template>
    <v-spacer />
    <template v-if="loggedIn">
      <v-card-actions class="pa-0 ">
        <v-spacer />
        <span class="caption charcoal--text text--lighten-2 mr-4 ">
          {{ version() }}
        </span>
      </v-card-actions>
      <v-sheet
        v-if="xvy"
        rounded
        class="transparent mx-2 charcoalTile paper--text"
        @click="setDebug"
      >
        <v-card-text
          v-if="debug != null"
          :class="textSizeSmall + ' pb-0 pt-2 text-center'"
        >
          DEBUG IS
          <span :class="debug ? 'orange--text' : 'silver--text'">
            {{ debug ? 'ON' : 'OFF' }}
          </span>
        </v-card-text>
        <v-spacer />
      </v-sheet>
      <v-sheet v-if="is_pro" rounded class="transparent charcoalTile">
        <v-card-text class="gold--text text-center">
          <span v-if="isPhone" :class="textSizeLarge">
            PRO
          </span>
          <span v-else :class="textSize">
            PRO
          </span>
        </v-card-text>
      </v-sheet>
      <v-card
       v-else
        rounded
        class="transparent mx-2 pa-2 charcoalTile paper--text"
        @click="getPro"
      >
        <v-card-text
          class=" charcoal--text text-center text-center rounded-lg py-2 px-5 charcoalTile mb-1 "
        >
        <div class="mx-10 rounded-lg gold py-2">
          <span v-if="isPhone" :class="textSizeLarge">
            GET PRO
          </span>
          <span v-else :class="textSize">
            GET PRO
          </span>
        </div>
        </v-card-text>
        <v-spacer />
        <v-card-text class="silver--text pa-0 text-center">
          Unlock BFITR's full potential & remove all limits.
        </v-card-text>
      </v-card>
    </template>
    <v-spacer />
    <v-card-actions class="mt-2 pa-0">
      <span class="caption charcoal--text text--lighten-2 ml-2">
        <v-btn
          class="pa-2"
          color="charcoal"
          text
          title="Provide Feedback"
          @click="reload()"
        >
          <v-icon small color="silver" class="mr-1">
            mdi-reload
          </v-icon>
          <span :class="textSizeXSmall + ' silver--text '">
            Reload
          </span>
        </v-btn>
      </span>
      <v-spacer />
      <v-btn
        v-if="loggedIn"
        class="pa-2"
        color="gold"
        text
        :small="isPhone"
        title="Provide Feedback"
        @click="launch()"
      >
        <span :class="textSizeSmall + ' gold--text '">
          Feedback
        </span>
      </v-btn>
    </v-card-actions>
    <v-sheet
      rounded
      class="py-2 ma-2 transparent charcoalTile silver--text caption"
    >
      <span v-if="!loggedIn">More tools are available once you login. </span>
      <v-card-actions v-if="loggedIn" :class="textSize + '  paper--text px-0'">
        <v-btn
          :retain-focus-on-click="false"
          color="transparent"
          text
          elevation="0"
          class="btn-fix"
          title="Your Account"
          @click="goTo('/myAccount')"
        >
          <v-icon large color="gold darken-2">
            mdi-account-circle-outline
          </v-icon>

          <span class="gold--text text--darken-1">
            Account
          </span>
        </v-btn>
        <v-spacer />

        <v-btn plain class="caption silver--text" @click="logout">
          <v-icon large color="silver">mdi-power-standby</v-icon>
          LOGOUT
        </v-btn>
      </v-card-actions>
      <v-card-actions v-else class="pr-2 px-1 ">
        <span class=" caption silver--text">
          <v-icon large @click="goTo('/')" color="silver"
            >mdi-home-variant-outline</v-icon
          >
          Home
        </span>
        <v-spacer />
        <span class="link caption silver--text" @click="goTo('/login')">
          <v-icon large color="silver">mdi-login</v-icon>
          Login
        </span>
      </v-card-actions>
    </v-sheet>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import items from '@/json/mainmenu.json'
import util from '@/mixins/util.js'
import { appConfig } from '@/store/helpers.js'
import Gleap from 'gleap'

export default {
  mixins: [util],
  mounted() {
    window.addEventListener('message', this.receiveMessage)
    this.debug = localStorage.getItem('debug') == 'true'
  },
  beforeDestroy() {
    window.removeEventListener('message', this.receiveMessage)
  },
  props: {
    show: {
      type: Boolean
    }
  },
  methods: {
    reload() {
      window.location.reload()
      this.$emit('cancel')
    },
    setDebug() {
      this.debug = !this.debug
      localStorage.setItem('debug', this.debug)
      this.debugNative(this.debug)
    },
    getPro() {
      this.upgrade()
      this.cancel()
    },
    launch() {
      const isOpened = Gleap.isOpened()
      if (!isOpened) {
        Gleap.identify(this.userid, {
          name: this.displayName,
          email: this.userInfo.email
        })
        Gleap.open()
      }
      this.$emit('close_menu')
    },
    receiveMessage(event) {
      if (event.data.type == 'CLOSE_MODAL' && event.data.key == 'UoMc92xqvA') {
        this.cancel()
      }
    },
    cancel() {
      this.$emit('close_menu')
    },
    getHeading() {
      let name = 'Stranger'
      if (this.loggedIn && this.userInfo.display_name != '') {
        name = this.userInfo.display_name
      }
      return 'Hi, ' + name + '!'
    },
    logout() {
      this.appLogout()
      this.$store.dispatch('session/logout')
      this.goTo('/')
      this.cancel()
    },
    goTo(path) {
      this.$emit('close_menu')
      if (this.$route.path != path) this.$router.push(path)
    },
    loadFull() {
      this.accountItems = this.items.filter(el => {
        return el.section == 'account' && el.private
      })

      this.workoutItems = this.items.filter(el => {
        return el.section == 'workout' && el.private
      })

      this.statsItems = this.items.filter(el => {
        return el.section == 'stats' && el.private
      })

      this.aiItems = this.items.filter(el => {
        return el.section == 'ai' && el.private
      })

      this.trackingItems = this.items.filter(el => {
        return el.section == 'tracking' && el.private
      })
      this.nutritionItems = this.items.filter(el => {
        return el.section == 'nutrition' && el.private
      })
      this.toolsItems = this.items.filter(el => {
        return el.section == 'tools' && el.private
      })

      this.learningItems = this.items.filter(el => {
        return el.section == 'learn' && el.private
      })

      this.miscItems = this.items.filter(el => {
        return el.section == 'misc'
      })

      this.$store.dispatch(
        'session/initializeMenu',
        this.$vuetify.breakpoint.mdAndUp
      )
    },
    loadPublic() {
      this.workoutItems = this.items.filter(el => {
        return el.section == 'workout' && el.public
      })

      this.statsItems = this.items.filter(el => {
        return el.section == 'stats' && el.public
      })
      this.toolsItems = this.items.filter(el => {
        return el.section == 'tools' && el.public
      })

      this.trackingItems = this.items.filter(el => {
        return el.section == 'tracking' && el.public
      })

      this.learningItems = this.items.filter(el => {
        return el.section == 'learn' && el.public
      })

      this.miscItems = this.items.filter(el => {
        return el.section == 'misc'
      })
    },
    collapseAll() {
      this.showWorkoutDetails = false
      this.showStatsDetails = false
      this.showNutritionDetails = false
      this.showTrackingDetails = false
      this.showToolsDetails = false
      this.showLearningDetails = false
      this.showMiscDetails = false
      this.showAIDetails = false
    },
    toggleWorkout() {
      let current = this.showWorkoutDetails
      this.collapseAll()
      this.showWorkoutDetails = !current
    },
    toggleAI() {
      let current = this.showAIDetails
      this.collapseAll()
      this.showAIDetails = !current
    },
    toggleStats() {
      let current = this.showStatsDetails
      this.collapseAll()
      this.showStatsDetails = !current
    },
    toggleNutrition() {
      let current = this.showNutritionDetails
      this.collapseAll()
      this.showNutritionDetails = !current
    },
    toggleTracking() {
      let current = this.showTrackingDetails
      this.collapseAll()
      this.showTrackingDetails = !current
    },
    toggleTools() {
      let current = this.showToolsDetails
      this.collapseAll()
      this.showToolsDetails = !current
    },
    toggleLearning() {
      let current = this.showLearningDetails
      this.collapseAll()
      this.showLearningDetails = !current
    },
    toggleMisc() {
      let current = this.showMiscDetails
      this.collapseAll()
      this.showMiscDetails = !current
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'session/userInfo'
    }),
    ...appConfig
  },
  data() {
    return {
      showWorkoutDetails: false,
      showStatsDetails: false,
      showNutritionDetails: false,
      showTrackingDetails: false,
      showToolsDetails: false,
      showLearningDetails: false,
      showMiscDetails: false,
      showAIDetails: false,
      items: items,
      accountItems: [],
      workoutItems: [],
      aiItems: [],
      statsItems: [],
      trackingItems: [],
      publicItems: [],
      toolsItems: [],
      learningItems: [],
      nutritionItems: [],
      miscItems: [],
      showAccount: false,
      debug: false
    }
  },
  watch: {
    loggedIn(val) {
      if (val) this.loadFull()
      else this.loadPublic()
    },
    show(value) {
      if (value) {
        this.collapseAll()
        this.$store.dispatch('session/loadSubscription', {})
      }
      this.$store.dispatch('session/toggleModal', value)
    }
  },
  beforeMount() {
    if (this.loggedIn) {
      this.loadFull()
    } else {
      this.loadPublic()
    }
  }
}
</script>
<style scoped>
.topright {
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  width: 375px;
}
</style>
